// const agent =
//     `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/agent`
// const agent_contacts = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/agent-contacts`
// const contact = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/contact`
// const disposition_codes = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/disposition-codes`
// const metrics = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/metrics`
// const phone_numbers = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/phone-numbers`

// const user_data = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/user/data`

// const user_info = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/user`
// const publish_notification = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/notification`
// const user_role = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/user/role`
// const push_notifications = `wss://${process.env.REACT_APP_NOTIFICATION_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/`

const dashboard = `wss://${process.env.REACT_APP_DASHBOARD_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_STAGE}/`
// const channel_url = `https://${process.env.REACT_APP_INSTANCE_NAME}.my.connect.aws/ccp-v2/channel-view/`
// const task_url = `https://${process.env.REACT_APP_INSTANCE_NAME}.my.connect.aws/ccp-v2/task-list/`
// const login_url = `https://${process.env.REACT_APP_INSTANCE_NAME}.my.connect.aws/login/`

// const schedule_url = `https://${process.env.REACT_APP_INSTANCE_NAME}.my.connect.aws/agent-app-v2/scheduling`

// const quicksight_embed_url = `https://${process.env.REACT_APP_API_ID}.execute-api.${process.env.REACT_APP_REGION}.amazonaws.com/default/dashboards`

const region = process.env.REACT_APP_REGION
const entra_app_id = process.env.REACT_APP_ENTRA_APP_ID
const entra_authority = process.env.REACT_APP_ENTRA_AUTHORITY
export {
    // agent,
    // contact,
    // agent_contacts,
    // disposition_codes,
    // metrics,
    // phone_numbers,
    // push_notifications,
    // channel_url,
    // task_url,
    // login_url,
    region,
    entra_authority,
    entra_app_id,
    // schedule_url,
    // user_data,
    // user_role,
    // publish_notification,
    // user_info,
    // quicksight_embed_url,
    dashboard
}