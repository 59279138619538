import React, { Component, Suspense } from 'react';

import * as RB from 'rebass';

import './App.css';
import '../scss/style.scss'

import HomePage from '../agentView/homepage';
import {
    BrowserRouter as Router,
    Routes,
    Route, BrowserRouter
} from "react-router-dom";
import Login from "../login/Login";
import Callback from "../callback/Callback";

import ProtectedRoute from "../config/ProtectedRoute";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={loading}>
                <Routes>
                    <Route path="/login" name="Login Page" element={<Login />} />
                    <Route path="/callback" name="Please Wait" element={<Callback />} />
                    <Route path="/wallboard" element={<ProtectedRoute element={HomePage} />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
