import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginRequest } from '../config/authConfig';
import { CButton, CCard, CCardBody, CContainer } from "@coreui/react-pro";

const Login = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const origin = queryParams.get('origin');

    const handleLogin = (loginType) => {
        const request = { ...loginRequest };

        if (loginType === "popup") {
            instance.loginPopup(request)
                .then(response => {
                    localStorage.setItem('authToken', response.accessToken); // Store token in local storage
                    navigate(`/callback?origin=${origin}`);
                })
                .catch(e => {
                    console.log(e);
                });
        } else if (loginType === "redirect") {
            instance.loginRedirect(request)
                .then(response => {
                    localStorage.setItem('authToken', response.accessToken); // Store token in local storage
                    navigate(`/callback?origin=${origin}`);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <h1 className="d-flex justify-content-center align-items-center">Wallboard</h1><br/>
                <CCard style={{ height: "250px" }}>
                    <CCardBody className="d-flex justify-content-center align-items-center">
                        <CButton color="primary" style={{ width: "500px" }} onClick={() => handleLogin("popup")}>Login</CButton>
                    </CCardBody>
                </CCard>
            </CContainer>
        </div>
    );
};

export default Login;