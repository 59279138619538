import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Callback = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const origin = queryParams.get('origin');

        if (origin) {
            console.log("Navigating to:", origin);
            navigate(origin);
        } else {
            console.error("Origin query parameter is missing!");
        }
    }, [location, navigate]);

    return null; // Or a loading indicator if needed
}

export default Callback;