import React from 'react';
import { Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const ProtectedRoute = ({ element: Component }) => {
    const { accounts } = useMsal();
    const nextHop = `/login?origin=${window.location.pathname}`;

    return accounts.length > 0 ? <Component /> : <Navigate to={nextHop} replace />;
};

export default ProtectedRoute;