import { STORE_USER_DATA } from './actionTypes';

export const setNotificationMessage = (message) => ({
  type: 'SET_NOTIFICATION_MESSAGE',
  message,
});

export const setNotificationType = (notificationType) => ({
  type: 'SET_NOTIFICATION_TYPE',
  notificationType,
});

export const setNotificationHeader = (notificationHeader) => ({
  type: 'SET_NOTIFICATION_HEADER',
  notificationHeader,
});

export const setCurrentBreadcrumb = breadcrumb => ({
  type: 'SET_CURRENT_BREADCRUMB',
  breadcrumb,
});
