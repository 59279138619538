import React, { useEffect, useState, useRef } from 'react';
import * as RB from 'rebass';
import './homepage.css';
import CanvasJSReact from '@canvasjs/react-charts';

import HeaderBar from '../headerBar/headerBar';
import Wallboard from "../wallboard/wallboard";
import Notification from "../notification/Notification";
import { connect } from 'react-redux';

import 'amazon-connect-streams';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
// import { user_info } from "../config/config";
import axios from "axios";
import { setNotificationHeader, setNotificationMessage, setNotificationType } from "../config/Redux/actions";
import { useNavigate } from "react-router-dom";


function HomePage(props) {
    const [contactID, setContactID] = useState();
    const [agentID, setAgentID] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [quicksightEmbedUrl, setQuicksightEmbedUrl] = useState(""); // State to hold QuickSight embed URL
    const [embeddingContext, setEmbeddingContext] = useState(null);
    const [embeddedDashboard, setEmbeddedDashboard] = useState(null);

    const [agentInit, setAgentInit] = useState(false);
    const [agentName, setAgentName] = useState("");
    const [agentStatus, setAgentStatus] = useState("");
    const [agentUsername, setAgentUsername] = useState("");
    const dashboardRef = useRef(null); // Ref for embedding QuickSight dashboard

    const navigate = useNavigate();

    function showNotification(message, notificationHeader, notificationType) {
        props.setNotificationMessage(message);
        props.setNotificationType(notificationType);
        props.setNotificationHeader(notificationHeader);
    }

    // const getUserInfo = async (userId) => {
    //     try {
    //         const response = await axios.get(`${user_info}/${userId}`);
    //         console.log("AGENT ROLE", response.data);
    //         setUserInfo(response.data);
    //     } catch (error) {
    //         console.error("Error fetching user info:", error);
    //         showNotification("<div>An error occurred. Please contact your Administrator!", "<div>Error Occurred</div>", "Error");
    //     }
    // };

    // const getQuicksightEmbedUrl = async () => {
    //     try {
    //         // Fetch the QuickSight embed URL from the Lambda function
    //         const response = await axios.get(quicksight_embed_url); // Adjust with your actual endpoint for Lambda
    //         setQuicksightEmbedUrl(response.data.EmbedUrl); // Assuming EmbedUrl is the key in the response
    //         console.log("QuickSight Embed URL:", response.data.EmbedUrl);
    //     } catch (error) {
    //         console.error("Error fetching QuickSight embed URL:", error);
    //         showNotification("Failed to load dashboard", "Error", "Error");
    //     }
    // };

    // useEffect(() => {
    //     // Check if provider is already initialized to prevent multiple initializations
    //     if (!window.amazonConnectProviderInitialized) {
    //         const { provider } = AmazonConnectApp.init({
    //             onCreate: (event) => {
    //                 const { appInstanceId } = event.context;
    //                 console.log('App initialized: ', appInstanceId);
    //             },
    //             onDestroy: (event) => {
    //                 console.log('App being destroyed');
    //             },
    //         });
    //
    //         window.amazonConnectProviderInitialized = true; // Prevent re-initialization
    //     }
    //
    //     // Initialize the AgentClient and retrieve the agent's ARN
    //     const agentClient = new AgentClient();
    //     agentClient.getName().then(res => {
    //         console.log("Name", res);
    //     });
    //     agentClient.getARN().then(res => {
    //         const userId = res.split('/').pop();
    //         setAgentID(userId); // Set agentID from ARN
    //         getUserInfo(userId);
    //         // getQuicksightEmbedUrl(); // Fetch the QuickSight URL after getting the user info
    //     }).catch(err => {
    //         console.error('Error fetching agent ARN:', err);
    //         showNotification('Failed to retrieve agent information.', 'Error', 'Error');
    //     });
    // }, []);  // Empty dependency array ensures this effect runs only once

    // Create QuickSight embedding context
//     const createContext = async () => {
//         const context = await createEmbeddingContext();
//         setEmbeddingContext(context);
//     };
//
//     useEffect(() => {
//         if (quicksightEmbedUrl) {
//             createContext(); // Create embedding context after URL is fetched
//         }
//     }, [quicksightEmbedUrl]);
//
//     // Function to embed the QuickSight dashboard
//     useEffect(() => {
//         const embedDashboard = async () => {
//             if (embeddingContext && dashboardRef.current) {
//                 const options = {
//         url: quicksightEmbedUrl,
//         container: dashboardRef.current,
//         height: "800px",
//         width: "100%",
//         scrolling: "no",
//     };
//
//     const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);
//     setEmbeddedDashboard(newEmbeddedDashboard);
// }
// };
//
// if (embeddingContext) {
//     embedDashboard();
// }
// }, [embeddingContext]);

    // Dynamically scale the container based on the viewport size
    const scale = Math.min(window.innerWidth / 1920, window.innerHeight / 1080);


    return (
        <>
            <div>
                <Notification />
                <HeaderBar
                    // agent={{
                    //     id: agentID,
                    //     username: userInfo.Username
                    // }}
                /><br/>
                {/*<CContainer>*/}
                    <Wallboard/>
                {/*</CContainer>*/}
                {/*<CContainer ref={dashboardRef} />*/}
            </div>
        </>
    );
}

const mapDispatchToProps = {
    setNotificationMessage,
    setNotificationHeader,
    setNotificationType
};

export default connect(null, mapDispatchToProps)(HomePage);
