import {useEffect, useRef, useState} from "react";
import {dashboard} from "../config/config";
import {CChart} from "@coreui/react-chartjs";
import './wallboard.css'
import {CCard, CCardBody, CCardHeader, CCardTitle, CCol, CContainer, CRow} from "@coreui/react-pro";
import Cookies from 'js-cookie';

function Wallboard() {
    const token = localStorage.getItem('authToken');
    const [metrics, setMetrics] = useState([]);
    const [routingProfileMetrics, setRoutingProfileMetrics] = useState({
        eng_stf: 0,
        spa_stf: 0,
        bus_stf: 0,
        ess2_stf: 0
    });

    const [queueMetrics, setQueueMetrics] = useState({
        eng_q: 0,
        eng_oc: 0,
        spa_q: 0,
        spa_oc: 0,
        bus_q: 0,
        bus_oc: 0,
        ess2_q: 0,
        ess2_oc: 0
    })

    const websocket = useRef(null);

    useEffect(() => {
        function parseQueueMetrics(queues){
            let eng_q = 0, eng_oc = 0, spa_q = 0, spa_oc = 0;
            let bus_q = 0, bus_oc = 0, ess2_q = 0, ess2_oc = 0;

            queues.forEach(queue => {
                if (queue.language.toLowerCase() === 'english') {
                    eng_q += queue.CONTACTS_IN_QUEUE;
                    eng_oc = Math.max(eng_oc, queue.OLDEST_CONTACT_AGE);
                } else if (queue.language.toLowerCase() === 'spanish') {
                    spa_q += queue.CONTACTS_IN_QUEUE;
                    spa_oc = Math.max(spa_oc, queue.OLDEST_CONTACT_AGE);
                }

                if (queue.lob.toLowerCase().includes('business')) {
                    bus_q += queue.CONTACTS_IN_QUEUE;
                    bus_oc = Math.max(bus_oc, queue.OLDEST_CONTACT_AGE);
                }

                if (queue.ess2.toLowerCase() === 'yes') {
                    ess2_q += queue.CONTACTS_IN_QUEUE;
                    ess2_oc = Math.max(ess2_oc, queue.OLDEST_CONTACT_AGE);
                }
            });

            setQueueMetrics({
                eng_q,
                eng_oc,
                spa_q,
                spa_oc,
                bus_q,
                bus_oc,
                ess2_q,
                ess2_oc
            });
        }
        function parseRoutingProfileMetrics(profiles) {
            let eng_stf = 0, spa_stf = 0, bus_stf = 0, ess2_stf = 0;


            profiles.forEach(profile => {
                if (profile.Language === 'Unknown') {
                    eng_stf += profile.AGENTS_STAFFED;
                } else if (profile.Language === 'Spanish') {
                    spa_stf += profile.AGENTS_STAFFED;
                }

                if (profile.LOB === 'Business') {
                    bus_stf += profile.AGENTS_STAFFED;
                }

                if (profile.Category === 'ESS2') {
                    ess2_stf += profile.AGENTS_STAFFED;
                }
            });

            setRoutingProfileMetrics({
                eng_stf,
                spa_stf,
                bus_stf,
                ess2_stf,
            });
        }

        function connect() {
            if (websocket.current) {
                websocket.current.close(); // Ensure no existing connection is open before connecting
            }
            websocket.current = new WebSocket(`${dashboard}?token=Bearer ${token}`);

            websocket.current.onopen = () => {
                console.log('WebSocket Connected');
            };

            websocket.current.onclose = event => {
                console.log('Dashboard WebSocket Disconnected. Attempting to reconnect...', event.reason);
                setTimeout(() => {
                    setMetrics([]);
                    connect(); // Reconnect after 1 second
                }, 1000);
            };

            websocket.current.onmessage = event => {
                const newMessage = JSON.parse(event.data);
                console.log("New metrics", newMessage);
                setMetrics(newMessage);

                if (newMessage.RoutingProfileMetrics) {
                    parseRoutingProfileMetrics(newMessage.RoutingProfileMetrics);
                }
                if (newMessage.QueueMetrics) {
                    parseQueueMetrics(newMessage.QueueMetrics)
                }
            };

            websocket.current.onerror = error => {
                console.error('WebSocket Error:', error);
                setMetrics([]);
            };
        }

        connect(); // Start the connection

        return () => {
            if (websocket.current) {
                websocket.current.close(); // Cleanup on component unmount
            }
        };
    }, []);

    function secondsToMS(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);

        // Pad with zeros if necessary
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(secs).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    return (
        <>
            <div id="wallbaordView">
                <CRow>
                    <CCol md={3} sm={6}>
                        <CCard className="text-center">
                            <CCardHeader className="bg-new-dark-blue">ENG STF</CCardHeader>
                            <CCardBody>
                                <CCardTitle>{routingProfileMetrics.eng_stf}</CCardTitle>
                            </CCardBody>
                        </CCard><br/>
                        <CRow>
                            <CCol md={6} xs={6} sm={6} className="remove-left-padding">
                                <CCard className="text-center">
                                    <CCardHeader className="bg-new-light-blue">ENG Q</CCardHeader>
                                    <CCardBody>
                                        <CCardTitle>{queueMetrics.eng_q}</CCardTitle>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol md={6} xs={6} sm={6} className="remove-right-padding">
                                <CCard className="text-center">
                                    <CCardHeader className="bg-new-light-blue">ENG OC</CCardHeader>
                                    <CCardBody>
                                        <CCardTitle>{secondsToMS(queueMetrics.eng_oc)}</CCardTitle>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                        <br className="visible-xs visible-sm"/>
                    </CCol>

                    <CCol md={3} sm={6}>
                        <CCard className="text-center">
                            <CCardHeader className="bg-new-dark-blue">SPA STF</CCardHeader>
                            <CCardBody>
                                <CCardTitle>{routingProfileMetrics.spa_stf}</CCardTitle>
                            </CCardBody>
                        </CCard><br/>
                        <CRow>
                            <CCol md={6} xs={6} sm={6} className="remove-left-padding">
                                <CCard className="text-center">
                                    <CCardHeader className="bg-new-light-blue">SPA Q</CCardHeader>
                                    <CCardBody>
                                        <CCardTitle>{queueMetrics.spa_q}</CCardTitle>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol md={6} xs={6} sm={6} className="remove-right-padding">
                                <CCard className="text-center">
                                    <CCardHeader className="bg-new-light-blue">SPA OC</CCardHeader>
                                    <CCardBody>
                                        <CCardTitle>{secondsToMS(queueMetrics.spa_oc)}</CCardTitle>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                        <br className="visible-xs visible-sm"/>
                    </CCol>

                    <CCol md={3} sm={6}>
                        <CCard className="text-center">
                            <CCardHeader className="bg-new-dark-blue">BUS STF</CCardHeader>
                            <CCardBody>
                                <CCardTitle>{routingProfileMetrics.bus_stf}</CCardTitle>
                            </CCardBody>
                        </CCard><br/>
                        <CRow>
                            <CCol md={6} xs={6} sm={6} className="remove-left-padding">
                                <CCard className="text-center">
                                    <CCardHeader className="bg-new-light-blue">BUS Q</CCardHeader>
                                    <CCardBody>
                                        <CCardTitle>{queueMetrics.bus_q}</CCardTitle>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol md={6} xs={6} sm={6} className="remove-right-padding">
                                <CCard className="text-center">
                                    <CCardHeader className="bg-new-light-blue">BUS OC</CCardHeader>
                                    <CCardBody>
                                        <CCardTitle>{secondsToMS(queueMetrics.bus_oc)}</CCardTitle>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                        <br className="visible-xs visible-sm"/>
                    </CCol>

                    <CCol md={3} sm={6}>
                        <CCard className="text-center">
                            <CCardHeader className="bg-new-dark-blue">ESS2 STF</CCardHeader>
                            <CCardBody>
                                <CCardTitle>{routingProfileMetrics.ess2_stf}</CCardTitle>
                            </CCardBody>
                        </CCard><br/>
                        <CRow>
                            <CCol md={6} xs={6} sm={6} className="remove-left-padding">
                                <CCard className="text-center">
                                    <CCardHeader className="bg-new-light-blue">ESS2 Q</CCardHeader>
                                    <CCardBody>
                                        <CCardTitle>{queueMetrics.ess2_q}</CCardTitle>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol md={6} xs={6} sm={6} className="remove-right-padding">
                                <CCard className="text-center">
                                    <CCardHeader className="bg-new-light-blue">ESS2 OC</CCardHeader>
                                    <CCardBody>
                                        <CCardTitle>{secondsToMS(queueMetrics.ess2_oc)}</CCardTitle>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
                <br/>
                <CRow className="align-items-center">
                    <CCol md={3}>
                        <CCard className="text-center">
                            <CCardHeader className="bg-new-light-blue">Call Queue</CCardHeader>
                            <CCardBody>
                                <CCardTitle>{metrics.ChannelMetrics?.CONTACTS_IN_QUEUE}</CCardTitle>
                            </CCardBody>
                        </CCard><br/>
                        <CCard className="text-center">
                            <CCardHeader className="bg-new-light-blue">Oldest Call</CCardHeader>
                            <CCardBody>
                                <CCardTitle>{secondsToMS(metrics.ChannelMetrics?.OLDEST_CONTACT_AGE)}</CCardTitle>
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol md={5} className="text-center">
                        <br className="visible-xs visible-sm"/><h3 className="heading" >Agent Status</h3><br/>
                        <CChart
                            type="pie"
                            data={{
                                labels: ['Available', 'On Contact', 'Online', 'Other'],
                                datasets: [
                                    {
                                        backgroundColor: ['#001689', '#FED600', '#4C9E45', '#009BDA'],
                                        data: [
                                            metrics.ChannelMetrics?.AGENTS_AVAILABLE || 0,
                                            metrics.ChannelMetrics?.AGENTS_ON_CALL || 0,
                                            metrics.ChannelMetrics?.AGENTS_ONLINE || 0,
                                            metrics.ChannelMetrics?.AGENTS_AFTER_CONTACT_WORK || 0 + metrics.ChannelMetrics?.AGENTS_NON_PRODUCTIVE || 0 + metrics.ChannelMetrics?.AGENTS_ERROR || 0
                                        ],
                                    },
                                ],
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false, // Allow chart to adjust height and width properly
                                radius: "100%", // Pie chart radius
                                plugins: {
                                    legend: {
                                        position: "top",
                                        labels: {
                                            // Customize legend labels if needed
                                        }
                                    }
                                },
                                layout: {
                                    padding: {
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0
                                    }
                                },
                            }}
                            style={{
                                height: "500px", // Adjust height as needed
                                maxHeight: "100%", // Ensure it doesn't overflow
                                width: "100%",
                            }}
                        />

                    </CCol>
                    <CCol md={4}>
                        <CCard>
                            <br/>
                            <CCardTitle className="heading">
                                Agent Status
                            </CCardTitle>
                            <CCardBody id="legend">
                                <CRow>
                                    <CCol md={4}>
                                        <span className="legendKey">Available</span>
                                    </CCol>
                                    <CCol md={8} className="remove-left-padding">
                                        <span>The number of agents who can take an inbound contact</span>
                                    </CCol>
                                </CRow><br/>
                                <CRow>
                                    <CCol md={4}>
                                        <span className="legendKey">On Contact</span>
                                    </CCol>
                                    <CCol md={8} className="remove-left-padding">
                                        <span>The number of agents currently on a contact (when they are handling at least one contact that is either connected, on hold, in ACW, paused or outbound ring)</span>
                                    </CCol>
                                </CRow><br/>
                                <CRow>
                                    <CCol md={4}>
                                        <span className="legendKey">Online</span>
                                    </CCol>
                                    <CCol md={8} className="remove-left-padding">
                                        <span>The number of agents who have set their status in the CCP to something other than offline</span>
                                    </CCol>
                                </CRow><br/>
                                <CRow>
                                    <CCol md={4}>
                                        <span className="legendKey">Other</span>
                                    </CCol>
                                    <CCol md={8} className="remove-left-padding">
                                        <span>The number of agents who have set their status in CCP to a custom status (status is not available or offline), have a contact in ACW, or are in an error state </span>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
        </>
    );
}

export default Wallboard;